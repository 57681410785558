import { createApi } from '@reduxjs/toolkit/query/react'
import { axiosBaseQuery } from '../../../../../store/utility';
import { backend_server_address } from '../../../../../backendServer';
import {BestPracticeResponse} from "./types";

export const bestPracticeAPI = createApi({
    reducerPath: 'bestPracticeAPI',
    baseQuery: axiosBaseQuery({ baseUrl: backend_server_address+'/api/'}),
    tagTypes: ['BestPractice', 'StandardBestPractices'],
    endpoints: (builder) => ({
        getBestPractices: builder.query<BestPracticeResponse[], any>({
            query: (params) => ({
                url: 'best_practice/',
                method: 'GET',
                withCredentials: true,
                params: params
            }),
            providesTags: (result, error, id) => {
                return result ?
                    [...result.map(bp => ({ type:'BestPractice' as const, id:bp.id})), 'BestPractice']
                    :[ 'BestPractice' ]
            }
        }),
        createBestPractice: builder.mutation({
            query: (data) =>({
                url: 'best_practice/',
                method: 'POST',
                withCredentials:true,
                data:data
            }),
            invalidatesTags: (result) => ['BestPractice'],
        }),
        editBestPractice: builder.mutation({
            query: ({id, data}) =>({
                url: 'best_practice/' + id + '/',
                method: 'PATCH',
                withCredentials:true,
                data:data
            }),
            invalidatesTags: (result) => result ? [{ type: 'BestPractice', id:result.id }]:['BestPractice'],
        }),
        getStandardBestPractices: builder.query({
            query: () => ({
                url: 'standard_best_practice/',
                method: 'GET',
                withCredentials: true,
            }),
            providesTags: (result) => [ 'StandardBestPractices' ]
        }),

    }),
});

export const {
    endpoints,
    useGetBestPracticesQuery,
    useCreateBestPracticeMutation,
    useEditBestPracticeMutation,
    useGetStandardBestPracticesQuery
} = bestPracticeAPI;
export const bestPracticeAPIReducer = bestPracticeAPI.reducer;
export const bestPracticeAPIReducerPath = bestPracticeAPI.reducerPath;
export const bestPracticeAPIReducerMiddleware = bestPracticeAPI.middleware;