import { createApi } from '@reduxjs/toolkit/query/react';
import {backend_server_address} from "../../../../../backendServer";
import axios from "axios";


const axiosBaseQuery =
    ({ baseUrl } = { baseUrl: '' }) =>
        async (axiosParams) => {
            try {
                axiosParams.url = baseUrl + axiosParams.url;
                const result = await axios.request(axiosParams);
                return { data: result.data }
            } catch (axiosError) {
                let err = axiosError;
                return {
                    error: { status: err.response ? err.response.status:undefined, data: err.response ? err.response.data:undefined },
                }
            }
        };

// Define a service using a base URL and expected endpoints
export const diagnosticsAPI = createApi({
    reducerPath: 'diagnosticsAPI',
    baseQuery: axiosBaseQuery({ baseUrl: backend_server_address+'/api/diagnostic/'}),
    tagTypes: ['Diagnostic', 'LastDiagnostic', 'DiagnosticMetadata'],
    endpoints: (builder) => ({
        getDiagnostics: builder.query({
            query: (params) => ({
                url: '',
                method: 'GET',
                withCredentials:true,
                params:params
            }),
            providesTags: (query) => {
                const results = query?.results ?? query
                return results ?
                    [...results.map(diagnostic => ({ type:'Diagnostic', id:diagnostic.id})), 'Diagnostic']
                    :[ 'Diagnostic' ]
            }
        }),
        getDiagnosticById: builder.query({
            query: (id) => ({
                url: `${id}/`,
                method: 'GET',
                withCredentials:true,
            }),
            providesTags: (result) => {
                return result ? [{type: 'Diagnostic', id: result.id}] : ['Diagnostic'];
            },
        }),
        getLastDiagnostics: builder.query({
            query: (params) => ({
                url: 'last/',
                method: 'GET',
                withCredentials:true,
                params:params
            }),
            providesTags: (result) => {
                return result ?
                    [{ type:'LastDiagnostic', id:'LastDiagnostic'}, 'LastDiagnostic']
                    :[ 'LastDiagnostic' ]
            }
        }),
        createDiagnostic: builder.mutation({
            query: ({data}) =>({
                url: '',
                method: 'POST',
                withCredentials:true,
                data:data
            }),
            invalidatesTags: (result) => ['Diagnostic'],
        }),
        editDiagnostic: builder.mutation({
            query: ({id, data}) => {
                return {
                    url: id +'/',
                    method: 'PATCH',
                    withCredentials: true,
                    data: data
                }
            },
            invalidatesTags: (result) => {
                return result ? [{type: 'Diagnostic', id: result.id}] : ['Diagnostic'];
            },
        }),
        editWorkOrder: builder.mutation({
            query: ({id, data}) => {
                return {
                    url: id +'/work_order/',
                    method: 'POST',
                    withCredentials: true,
                    data: data
                }
            },
            invalidatesTags: (result) => {
                return result ? [{type: 'Diagnostic', id: result.diagnostic}] : ['Diagnostic'];
            },
        }),
        getDiagnosticTracking: builder.query({
            query: (params) => ({
                url: 'tracking/',
                method: 'GET',
                withCredentials: true,
                params: params
            }),
            providesTags: (query) => {
                const results = query?.results ?? query
                return results ?
                    [...results.map(diagnostic => ({ type:'Diagnostic', id:diagnostic.id})), 'Diagnostic']
                    :[ 'Diagnostic' ]
            }
        }),
        createCompoundDiagnostic: builder.mutation({
            query: ({data}) =>({
                url: 'compound/',
                method: 'POST',
                withCredentials:true,
                data: data
            }),
            invalidatesTags: (result) => {
                return result ? [{type: 'Diagnostic', id: result.id}] : ['Diagnostic'];
            },
        }),
        editCompoundDiagnostic: builder.mutation({
            query: ({id, data}) => {
                return {
                    url: `${id}/compound/`,
                    method: 'PATCH',
                    withCredentials: true,
                    data: data
                }
            },
            invalidatesTags: (result) => {
                return result ? [{type: 'Diagnostic', id: result.id}] : ['Diagnostic'];
            },
        }),
        getDiagnosticMetadata: builder.query({
            query: (params) => ({
                url: 'metadata/',
                method: 'GET',
                withCredentials: true,
                params: params
            }),
            providesTags: (result) => ['DiagnosticMetadata']
        }),

    }),
});