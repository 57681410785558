import { createApi } from '@reduxjs/toolkit/query/react'
import {backend_server_address} from "../../../../../backendServer";
import { axiosBaseQuery } from '../../../../../store/utility';

export const managementNodesAPI = createApi({
    reducerPath: 'managementNodesAPI',
    baseQuery: axiosBaseQuery({ baseUrl: backend_server_address+'/api/management_nodes/'}),
    endpoints: (builder) => ({
        editManagementNode: builder.mutation({
            query: ({node_type, node_id, data}) => {
                return {
                    url: `${node_type}/${node_id}/`,
                    method: 'PATCH',
                    withCredentials: true,
                    data: data
                }
            },
        }),
    }),
});

export const {
    endpoints,
    useEditManagementNodeMutation,
} = managementNodesAPI;
export const managementNodesAPIReducer = managementNodesAPI.reducer;
export const managementNodesAPIReducerPath = managementNodesAPI.reducerPath;
export const managementNodesAPIReducerMiddleware = managementNodesAPI.middleware;